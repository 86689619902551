/** @jsxImportSource @emotion/react */
import { Backdrop, CircularProgress, css, TextField } from "@mui/material";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useState, VFC } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { AiOutlineWarning } from "react-icons/ai";
import { CountryPath } from "src/entities/CountryPath";
import { PublisherInfoResponse } from "src/entities/PublisherInfoResponse";
import { UserInfoResponse } from "src/entities/UserInfoResponse";
import { Auth } from "src/hooks/useAuth";
import { useFetch } from "src/hooks/useFetch";
import { useUpdateInfo } from "src/hooks/useUpdateInfo";
import getCountryCode from "src/utils/getContryCode";
import Button from "../components/common/Button";
import InputField from "../components/common/InputField";
import EditPublisherInfoHelpModal from "../components/modal/EditPublisherInfoHelpModal";
import PageHeader from "../components/page/PageHeader";
import PageWrapper from "../components/page/PageWrapper";

interface Props {
  auth: Auth;
  country: CountryPath;
}

const EditPublisherInfoPage: VFC<Props> = (props: Props) => {
  /**
   * Common parameters
   */
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [hasGotUserInfo, setHasGotUserInfo] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  /**
   * company info
   */
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [headquarters, setHeadquarters] = useState("");
  const [founded, setFounded] = useState(0);
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [twitter, setTwitter] = useState("");
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } =
    useDropzone({
      multiple: false,
      maxSize: 5 * 1024 * 1024,
      onDropRejected: () => {
        enqueueSnackbar("File size exceeds the 5MB limit", {
          variant: "error",
        });
      },
    });

  /**
   * Get user info
   */
  const { data: userInfo, isLoading: isUserInfoLoading } =
    useFetch<UserInfoResponse>({
      url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publisherMembers/me`,
      onSuccess: () => {
        setHasGotUserInfo(true);
      },
    });

  /**
   * Get publisher info
   */
  const { data: publisherInfo, isLoading: isPublisherInfoLoading } =
    useFetch<PublisherInfoResponse>({
      url: `${process.env.REACT_APP_DOMAIN}/api/v1/internal/publishers/${userInfo?.publisher_id}`,
      headers: {
        country: props.country,
      },
      skip: !hasGotUserInfo || userInfo?.publisher_id === undefined,
      onSuccess: (data) => {
        if (data) {
          setName(data.publisher_name);
          setWebUrl(data.url);
          setPhoneNumber(data.phone_number);
          setDescription(data.summary);
          setFounded(data.founded);
          setHeadquarters(data.headquarters);
          setFacebook(data.facebook_url);
          setTwitter(data.twitter_url);
          setLinkedin(data.linkedin_url);
          setYoutube(data.youtube_url);
          setTiktok(data.tiktok_url);
          setInstagram(data.instagram_url);
        }
      },
      onError: () => {
        enqueueSnackbar(t("error.login_again"), {
          variant: "error",
        });
      },
    });

  /**
   * Dropdown settings
   */
  /**
   * Dropdown parameters
   */
  const [backdropOpen, setBackdropOpen] = useState(false);
  /**
   * Open backdrop
   */
  const handleBackDropOpen = () => {
    setBackdropOpen(true);
  };
  /**
   * Close backdrop
   */
  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const { updateInfo } = useUpdateInfo({
    input: {
      publisherId: userInfo?.publisher_id,
      country: props.country,
      acceptedFiles: acceptedFiles,
      name: name,
      description: description,
      webUrl: webUrl,
      industryCategory: publisherInfo?.industry_category,
      businessCategories: publisherInfo?.business_categories
        ? publisherInfo.business_categories
        : [],
      headquarters: headquarters,
      phoneNumber: phoneNumber,
      founded: founded,
      logoUrl: publisherInfo?.logo_url,
      twitter: twitter,
      facebook: facebook,
      tiktok: tiktok,
      youtube: youtube,
      instagram: instagram,
      linkedin: linkedin,
    },
    functions: {
      handleBackDropOpen: handleBackDropOpen,
      handleBackdropClose: handleBackdropClose,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <EditPublisherInfoHelpModal
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        href={`https://www.vritimes.com/${getCountryCode(
          props.country
        )}/articles/${publisherInfo?.id}`}
      />
      <PageWrapper>
        <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={backdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <PageHeader
          title={t("edit_company_info.page_header")}
          isHelp
          onClickHelp={() => {
            setOpen(true);
          }}
        />
        {isPublisherInfoLoading || isUserInfoLoading ? (
          <div className="flex h-full w-full justify-center">
            <CircularProgress />
          </div>
        ) : publisherInfo ? (
          <>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div className="flex items-center mb-2">
                <div
                  css={contentTitle}
                  className="flex items-center"
                  style={{ marginBottom: 0 }}
                >
                  {t("edit_company_info.content.name")}
                </div>
                <div className="whitespace-nowrap flex ml-2 text-xs text-gray-500">
                  <AiOutlineWarning size="16px" style={{ marginRight: 4 }} />
                  {t("edit_company_info.aioutline_warning")}
                </div>
              </div>
              <InputField
                value={name}
                type="text"
                onChange={(value) => {
                  setName(value);
                }}
                placeholder={t("edit_company_info.content.name_placeholder")}
              />
            </div>
            <div style={{ width: "75%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.description")}
              </div>
              <InputField
                value={description}
                multiline
                type="text"
                onChange={(value) => {
                  setDescription(value);
                }}
                placeholder={t(
                  "edit_company_info.content.description_placeholder"
                )}
              />
            </div>
            <div style={{ marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.company_logo")}
              </div>
              <div style={{ display: "flex" }}>
                <div
                  css={thumbnailStyle}
                  style={{
                    borderColor: isDragAccept ? "#00e676" : "#eeeeee",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p>
                    {t("edit_company_info.content.company_logo_placeholder_1")}
                  </p>
                  <p>
                    {t("edit_company_info.content.company_logo_placeholder_2")}
                  </p>
                </div>
                <div>
                  {acceptedFiles.length > 0 ? (
                    acceptedFiles.map((file) => {
                      return (
                        <div className="border ml-4 rounded-lg">
                          <img
                            className="object-cover rounded-lg"
                            key={file.name}
                            src={URL.createObjectURL(file)}
                            alt="preview"
                            style={{ height: 120, width: 120 }}
                          />
                        </div>
                      );
                    })
                  ) : publisherInfo?.logo_url ? (
                    <div className="border ml-4 rounded-lg">
                      <img
                        className="object-cover rounded-lg"
                        key="thumbnail"
                        src={publisherInfo?.logo_url}
                        alt="preview"
                        style={{ height: 120, width: 120 }}
                      />
                    </div>
                  ) : (
                    <div
                      className="border rounded-lg"
                      style={{ height: 120, width: 120, marginLeft: 24 }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.web_url")}
              </div>
              <InputField
                value={webUrl}
                type="text"
                onChange={(value) => {
                  setWebUrl(value);
                }}
                placeholder={t("edit_company_info.content.web_url_placeholder")}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.phone_number")}
              </div>
              <InputField
                value={phoneNumber}
                type="text"
                onChange={(value) => {
                  setPhoneNumber(value);
                }}
                placeholder={t(
                  "edit_company_info.content.phone_number_placeholder"
                )}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.headquarters")}
              </div>
              <InputField
                value={headquarters}
                type="text"
                onChange={(value) => {
                  setHeadquarters(value);
                }}
                placeholder={t(
                  "edit_company_info.content.headquarters_placeholder"
                )}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.founded")}
              </div>
              <DesktopDatePicker
                value={
                  founded === 0
                    ? null
                    : DateTime.fromFormat(founded.toString(), "yyyy")
                }
                views={["year"]}
                maxDate={DateTime.now()}
                minDate={DateTime.fromFormat("1900", "yyyy")}
                onChange={(value) => {
                  setFounded(value.year);
                }}
                // renderInput={(parameters) => <TextField {...parameters} />}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.facebook")}
              </div>
              <InputField
                value={facebook}
                type="text"
                onChange={(value) => {
                  setFacebook(value);
                }}
                placeholder={t(
                  "edit_company_info.content.facebook_placeholder"
                )}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.twitter")}
              </div>
              <InputField
                value={twitter}
                type="text"
                onChange={(value) => {
                  setTwitter(value);
                }}
                placeholder={t("edit_company_info.content.twitter_placeholder")}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.linkedin")}
              </div>
              <InputField
                value={linkedin}
                type="text"
                onChange={(value) => {
                  setLinkedin(value);
                }}
                placeholder={t(
                  "edit_company_info.content.linkedin_placeholder"
                )}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.youtube")}
              </div>
              <InputField
                value={youtube}
                type="text"
                onChange={(value) => {
                  setYoutube(value);
                }}
                placeholder={t("edit_company_info.content.youtube_placeholder")}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.instagram")}
              </div>
              <InputField
                value={instagram}
                type="text"
                onChange={(value) => {
                  setInstagram(value);
                }}
                placeholder={t(
                  "edit_company_info.content.instagram_placeholder"
                )}
              />
            </div>
            <div style={{ width: "50%", marginBottom: 24 }}>
              <div css={contentTitle}>
                {t("edit_company_info.content.tiktok")}
              </div>
              <InputField
                value={tiktok}
                type="text"
                onChange={(value) => {
                  setTiktok(value);
                }}
                placeholder={t("edit_company_info.content.tiktok_placeholder")}
              />
            </div>
            <div css={cautionStyle}>
              <AiOutlineWarning size="28px" style={{ marginRight: 8 }} />
              <div>
                <div className="whitespace-pre-wrap">
                  {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                  {t("edit_press_release.publisher.msg")}
                </div>
                <a
                  href={`https://www.vritimes.com/articles/${publisherInfo?.id}`}
                  className="font-bold underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("edit_press_release.publisher.check")}
                </a>
              </div>
            </div>
            <div className="flex justify-end my-4">
              <Button
                text={t("edit_company_info.button.save")}
                onClick={() => {
                  updateInfo();
                }}
                className="mr-4"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="mb-2 font-semibold">{t("error.login_again")}</div>
          </div>
        )}
      </PageWrapper>
    </LocalizationProvider>
  );
};

const thumbnailStyle = css`
  flex: 1;
  max-width: 50%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

const contentTitle = css`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(45, 45, 45, 0.8);
  margin-bottom: 8px;
  white-space: nowrap;
`;

const cautionStyle = css`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  background-color: #fafafa;
  color: #808080;
`;

export default EditPublisherInfoPage;
